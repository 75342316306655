// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-apps-pioneers-privacy-js": () => import("./../../../src/pages/apps/pioneers/privacy.js" /* webpackChunkName: "component---src-pages-apps-pioneers-privacy-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insights-an-open-letter-to-portillos-from-our-ceo-js": () => import("./../../../src/pages/insights/an-open-letter-to-portillos-from-our-ceo.js" /* webpackChunkName: "component---src-pages-insights-an-open-letter-to-portillos-from-our-ceo-js" */),
  "component---src-pages-insights-five-exciting-off-premise-technologies-for-2021-js": () => import("./../../../src/pages/insights/five-exciting-off-premise-technologies-for-2021.js" /* webpackChunkName: "component---src-pages-insights-five-exciting-off-premise-technologies-for-2021-js" */),
  "component---src-pages-insights-how-small-restaurants-can-break-up-with-grubhub-and-ubereats-js": () => import("./../../../src/pages/insights/how-small-restaurants-can-break-up-with-grubhub-and-ubereats.js" /* webpackChunkName: "component---src-pages-insights-how-small-restaurants-can-break-up-with-grubhub-and-ubereats-js" */),
  "component---src-pages-insights-index-js": () => import("./../../../src/pages/insights/index.js" /* webpackChunkName: "component---src-pages-insights-index-js" */),
  "component---src-pages-insights-is-bring-your-own-device-the-future-of-in-house-ordering-js": () => import("./../../../src/pages/insights/is-bring-your-own-device-the-future-of-in-house-ordering.js" /* webpackChunkName: "component---src-pages-insights-is-bring-your-own-device-the-future-of-in-house-ordering-js" */),
  "component---src-pages-insights-why-restaurants-should-evolve-their-in-house-ordering-technology-in-a-post-covid-19-world-js": () => import("./../../../src/pages/insights/why-restaurants-should-evolve-their-in-house-ordering-technology-in-a-post-covid-19-world.js" /* webpackChunkName: "component---src-pages-insights-why-restaurants-should-evolve-their-in-house-ordering-technology-in-a-post-covid-19-world-js" */),
  "component---src-pages-process-js": () => import("./../../../src/pages/process.js" /* webpackChunkName: "component---src-pages-process-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-finelite-js": () => import("./../../../src/pages/work_finelite.js" /* webpackChunkName: "component---src-pages-work-finelite-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-yum-js": () => import("./../../../src/pages/work_yum.js" /* webpackChunkName: "component---src-pages-work-yum-js" */)
}

